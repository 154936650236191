<template>
  <div class="app-container">
    <div class="page-title" style="display:flex; flex-direction:row">
      <h2>{{ $t('controleur.controleurs') }}</h2>
      <h2>👷🏿‍♂️</h2>
    </div>
    <el-row :gutter="40" class="panel-group">
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
        <div class="card-panel">
          <div class="card-panel-icon-wrapper icon-people">
            <h1>👷🏿‍♂</h1>
          </div>
          <div class="card-panel-description">
            <div class="card-panel-text">
              Total Controleur
            </div>
            <count-to :start-val="0" :end-val="list ? list.length : 0" :duration="2600" class="card-panel-num" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="desactives" border @change="getSubControleursList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterControleurs">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubControleursList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
        <el-select v-model="filterGradeId" border class="filter-item" :placeholder="$t('gradeControleur.gradeControleur')" prop="grade_id" @change="filterByGradesControleurs">
          <el-option
            key="-1"
            label="Tout"
            value=""
          />
          <el-option
            v-for="(gradeControleur, idex) in gradeControleurs"
            :key="idex"
            :label="gradeControleur.nom | uppercaseFirst"
            :value="gradeControleur.id"
            :disabled="!gradeControleur.is_active"
          />
        </el-select>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <!-- <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterControleurs" /> -->
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" clearable @input="handleFilterControleurs" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterControleurs">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer controleur'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateControleur">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <!-- <el-table-column align="center" label="Code" min-width="110px">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column> -->
        <el-table-column align="center" :label="$t('controleur.nom')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.nom + ' '+ scope.row.prenom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('controleur.numero')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.numero }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('controleur.email')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.email }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('controleur.grade')" min-width="80px">
          <template slot-scope="scope">
            <span>{{ nomGradeControleur[scope.row.grade_id] }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['activer controleur'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setControleurStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkRole(['admin']) && (activeList==='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setControleurStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['modifier controleur','visible controleur','supprimer controleur'])" align="center" :label="1===1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button-group>
              <el-button v-if="scope.row.is_visible && checkPermission(['modifier controleur'])" type="primary" size="small" icon="el-icon-edit" @click="handleEditControleur(scope.row.id);" />
              <el-button v-if="checkPermission(['visible controleur','supprimer controleur'])" type="danger" size="small" icon="el-icon-delete" @click="handleDeleteControleur(scope.row.id, scope.row.is_visible, scope.row.libelle);" />
              <el-popover
                placement="bottom"
                title="Autres options"
                width="200"
                trigger="click"
              >
                <el-button
                  v-if="nomGradeControleur[scope.row.grade_id] === 'Senior'"
                  @click="handleDisplayControleurZoneDialog(scope.row.zones)"
                >
                  Zones
                </el-button>
                <el-button v-if="nomGradeControleur[scope.row.grade_id] === 'Senior'" style="margin-left:0px; margin-top:3px" @click="handleDisplayZoneDialog(scope.row)">Lui affecter une zone</el-button>
                <el-button
                  style="margin-left:0px; margin-top:3px"
                  @click="handleDisplayControleurGroupementDialog(scope.row)"
                >
                  Groupements
                </el-button>
                <!-- el-button style="margin-left:0px; margin-top:3px" @click="handleDisplayGroupementDialog(scope.row)">Lui affecter un groupement</el-button-->
                <el-button v-if="scope.row.grade.nom === 'Superviseur'" style="margin-left:0px; margin-top:3px" @click="gererUpControleur(scope.row)">Affecter UP</el-button>
                <!--el-button style="margin-left:0px; margin-top:3px" @click="handleDisplayAffectUserDialog(scope.row)">Donner accès</el-button-->
                <el-button style="margin-left:0px; margin-top:3px" @click="showDetailsPage(scope.row.id)">Détails</el-button>
                <el-button slot="reference" type="info" size="small" icon="el-icon-more" />
              </el-popover>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > query.limit" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getControleursList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('controleur.edit') + ' ' + currentControleur.nom_prenom : $t('controleur.AddControleur')"
        :visible.sync="dialogCreateControleur"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="controleurCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="controleurForm" :rules="rules" :model="currentControleur" label-position="left">
            <el-form-item :label="$t('gradeControleur.gradeControleur')" prop="grade_id">
              <el-select v-model="currentControleur.grade_id" class="filter-item" :placeholder="$t('gradeControleur.gradeControleur')" style="width: 100%;" prop="grade_id">
                <el-option
                  v-for="(gradeControleur, idex) in gradeControleurs"
                  :key="idex"
                  :label="gradeControleur.nom | uppercaseFirst"
                  :value="gradeControleur.id"
                  :disabled="!gradeControleur.is_active"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('controleur.nom')" prop="nom">
              <el-input v-model="currentControleur.nom" />
            </el-form-item>
            <el-form-item :label="$t('controleur.prenom')" prop="prenom">
              <el-input v-model="currentControleur.prenom" />
            </el-form-item>
            <!-- <el-form-item :label="$t('controleur.username')" prop="username">
              <el-input v-model="currentControleur.username" />
            </el-form-item> -->
            <el-form-item :label="$t('controleur.email')" prop="email">
              <el-input v-model="currentControleur.email" />
            </el-form-item>
            <el-form-item :label="$t('controleur.password')" prop="password">
              <el-input v-model="currentControleur.password" />
            </el-form-item>
            <el-form-item :label="$t('controleur.numero')" prop="numero">
              <el-input v-model="currentControleur.numero" />
            </el-form-item>
            <el-form-item :label="$t('controleur.adresse')" prop="adresse">
              <el-input v-model="currentControleur.adresse" />
            </el-form-item>
            <el-form-item :label="$t('controleur.controleur_structure')" prop="controleur_structure">
              <!-- <el-input v-model="currentControleur.controleur_structure" /> -->
              <el-switch v-model="currentControleur.controleur_structure" active-color="#13ce66" inactive-color="#ff4949" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateControleur=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="controleurCreating" @click="editing ? updateControleur() : createControleur()">
              {{ controleurCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        title="Affectation de zones"
        :visible.sync="zoneAffectationDiolog"
        width="30%"
        center
      >
        <el-form ref="zoneForm" :rules="rules" :model="currentControleur" label-position="left">
          <el-form-item :label="$t('zone.zone')" prop="zone_id">
            <el-select v-model="affecterZone.zone_id" class="filter-item" :placeholder="$t('zone.zone')" style="width: 100%;" prop="grade_id">
              <el-option
                v-for="(zone, index) in zones"
                :key="index"
                :label="zone.nom | uppercaseFirst"
                :value="zone.id"
                :disabled="!zone.is_active"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('controleur.nom')" prop="controleur_id">
            <el-input v-model="affecterZone.user" disabled />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="zoneAffectationDiolog = false">Annuler</el-button>
          <el-button type="primary" @click="affecterZoneControleur()">Confirmer</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="Affectation d'utilisateur"
        :visible.sync="userAffectationDialog"
        width="30%"
        center
      >
        <el-form ref="userForm" :rules="rules" :model="affecterUtilisateur" label-position="left">
          <el-form-item :label="$t('user.user')" prop="user_id">
            <el-select v-model="affecterUtilisateur.user_id" class="filter-item" :placeholder="$t('user.user')" style="width: 100%;" prop="grade_id">
              <el-option
                v-for="(user, index) in users"
                :key="index"
                :label="user.nom | uppercaseFirst"
                :value="user.id"
                :disabled="!user.is_active"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('controleur.nom')" prop="controleur_id">
            <el-input v-model="affecterUtilisateur.user" disabled />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="userAffectationDialog = false">Annuler</el-button>
          <el-button type="primary" @click="affecterUserControleur()">Confirmer</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="Affectation de groupements"
        :visible.sync="groupementAffectationDiolog"
        width="30%"
        center
      >
        <el-form ref="groupementForm" :rules="rules" :model="currentControleur" label-position="left">
          <el-form-item :label="$t('groupement.groupement')" prop="groupement_id">
            <el-select v-model="affecterGroupement.groupement_id" class="filter-item" :placeholder="$t('groupement.groupement')" style="width: 100%;" prop="groupement_id">
              <el-option
                v-for="(groupement, index) in groupements"
                :key="index"
                :label="groupement.nom | uppercaseFirst"
                :value="groupement.id"
                :disabled="!groupement.is_active"
              >
                <el-checkbox :value="getExist(groupement.id)" @change="checkGroupementSelectId(groupement.id)">{{ groupement.nom | uppercaseFirst }}</el-checkbox>
              </el-option>
            </el-select>
            <el-tag v-for="(groupementId, index) in groupementSelection" :key="index">{{ nomGroupementsDispo[groupementId] }}</el-tag>
          </el-form-item>
          <el-form-item :label="$t('controleur.nom')" prop="controleur_id">
            <el-input v-model="affecterGroupement.user" disabled />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="groupementAffectationDiolog = false">Annuler</el-button>
          <el-button :disabled="groupementSelection.length === 0" type="primary" @click="affecterGroupementControleur()">Confirmer</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="Affectation d unite production"
        :visible.sync="AffectUpDiolog"
        width="30%"
        center
      >
        <el-form ref="UpForm" :rules="rules" :model="currentControleur" label-position="left">
          <el-form-item label="Unite Productions" prop="up_id">
            <el-select v-model="affecterUp.up_id" class="filter-item" placeholder="Unite de Production" style="width: 100%;" prop="up_id">
              <el-option
                v-for="(up, index) in unite_productions"
                :key="index"
                :label="up.nom | uppercaseFirst"
                :value="up.id"
                :disabled="!up.is_active"
              >
                <el-checkbox :value="getUpExist(up.id)" @change="checkUpSelectId(up.id)">{{ up.nom | uppercaseFirst }}</el-checkbox>
              </el-option>
            </el-select>
            <el-tag v-for="(upId, index) in upSelection" :key="index">{{ nomUpDispo[upId] }}</el-tag>
          </el-form-item>
          <el-form-item :label="$t('controleur.nom')" prop="controleur_id">
            <el-input v-model="affecterUp.user" disabled />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="AffectUpDiolog = false">Annuler</el-button>
          <el-button :disabled="upSelection.length ===0" type="primary" @click="affecterUpControleur()">Confirmer</el-button>
        </span>
      </el-dialog>

      <el-dialog title="Groupements du contrôleur" :visible.sync="controleurGroupementDialog">
        <!--        <el-input v-model="grpt" :placeholder="Rechercher" class="wd-200 mr-15 filter-item" clearable @input="searchGroupement(grpt)" />-->
        <!-- el-button v-show="grade_controleur === 'Superviseur'" @click="gererGroupementControleur(controleur_id)"-->
        <el-button @click="gererGroupementControleur(controleur_id)">
          Affecter au Controleur
        </el-button>
        <el-table v-loading="groupementLoading" :data="groupementsControleur">
          <el-table-column property="nom" label="nom" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.nom }}</span>
            </template>
          </el-table-column>
          <el-table-column property="description" label="description" width="auto">
            <template slot-scope="scope">
              <span>{{ scope.row.description }}</span>
            </template>
          </el-table-column>
          <el-table-column property="up" label="UP" width="auto">
            <template slot-scope="scope">
              <span>{{ scope.row.unite_production.nom }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Actions" width="auto">
            <template slot-scope="scope">
              <el-button type="info" icon="el-icon-view" @click="navigateGroupement(scope.row.id)" />
              <el-button v-if="scope.row.unite_production.controleur_id !== scope.row.controleur_id" type="warning" @click="retirerGroupement(scope.row.id,scope.row.responsable)">Retirer</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>

      <el-dialog title="Zones du contrôleur" :visible.sync="controleurZoneDialog">
        <el-table :data="currentZoneData">
          <el-table-column property="nom" label="nom" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.nom }}</span>
            </template>
          </el-table-column>
          <el-table-column property="description" label="description" width="auto">
            <template slot-scope="scope">
              <span>{{ scope.row.description }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <!-- ======= REGION USER DIALOG ======= -->

      <el-dialog title="Transferer Groupements" :visible.sync="affectGroupementControleurDialog">
        <div class="components-container">
          <el-input v-model="grpt_name" placeholder="Rechercher" class="wd-200 mr-15 filter-item" clearable @input="findGroupement(grpt_name)" />
          <el-select v-model="controleur_beneficiaire_id" class="wd-200 mr-15 filter-item" placeholder="Controleur Beneficiaire" style="width: 100%;" prop="groupement_id">
            <el-option
              v-for="(controleur, index) in select_list"
              :key="index"
              :label="controleur.nom_prenom"
              :value="controleur.id"
              :disabled="!controleur.is_active"
            />
          </el-select>
          <el-button class="wd-50 mr-15 filter-item" type="primary" @click="envoyerGroupementsControleur(controleur_beneficiaire_id)"> Affecter </el-button>
          <div class="editor-container">
            <dnd-list :list1="list1" :list2="list2" list2-title="Vos groupements" list1-title="Groupements à Affecter" />
          </div>
        </div>
      </el-dialog>
      <el-dialog title="Transferer Unite production " :visible.sync="affectUniteProductionDialog">
        <div class="components-container">
          <el-input v-model="up_name" placeholder="Rechercher" class="wd-200 mr-15 filter-item" clearable @input="findUp(up_name)" />
          <el-select v-model="controleur_beneficiaire_id" class="wd-200 mr-15 filter-item" placeholder="Controleur Beneficiaire" style="width: 100%;" prop="groupement_id">
            <el-option
              v-for="(controleur, index) in select_list"
              :key="index"
              :label="controleur.nom_prenom"
              :value="controleur.id"
              :disabled="!controleur.is_active"
            />
          </el-select>
          <el-button class="wd-50 mr-15 filter-item" type="primary" @click="affecterUpControleur(controleur_beneficiaire_id)"> Affecter </el-button>
          <div class="editor-container">
            <dnd-list :list1="list1" :list2="list2" list2-title="Vos Unites de Productions" list1-title="Unites de Production à Affecter" />
          </div>
        </div>
      </el-dialog>

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission4
import DndList from '@/components/DndList';
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import CountTo from 'vue-count-to';
const gradeControleurResource = new Resource('grade_controleurs');
const controleurResource = new Resource('controleurs');
const zoneResource = new Resource('zones');
const groupementResource = new Resource('groupements');
const upResource = new Resource('unite_productions');
const userResource = new Resource('users');
const lierResource = new Resource('controleurs_lier_user');
const retirerResource = new Resource('groupement/retirer_affectation_groupement');
export default {
  name: 'ControleursList',
  components: { Pagination, CountTo, DndList },
  directives: { waves, permission, role },
  data() {
    return {
      zoneAffectationDiolog: false,
      grpt: '',
      currentGroupementData: null,
      affectGroupementControleurDialog: false,
      affectUniteProductionDialog: false,
      currentZoneData: null,
      grade_controleur: '',
      up_controleur: [],
      controleur_id: null,
      groupementAffectationDiolog: false,
      userAffectationDialog: false,
      controleurGroupementDialog: false,
      AffectUpDiolog: false,
      groupementLoading: true,
      controleurZoneDialog: false,
      affectTitile: '',
      gradeControleurs: [],
      nomGradeControleur: [],
      nomGroupementsDispo: [],
      groupementSelection: [],
      upSelection: [],
      controleur_beneficiaire_id: null,
      groupementsControleur: [],
      list: null,
      listback: null,
      nameSearch: '',
      grpt_name: '',
      up_name: '',
      zones: null,
      users: null,
      groupements: null,
      unite_productions: null,
      total: 0,
      affectUpButton: false,
      affectGrpButton: false,
      loading: true,
      downloading: false,
      activeList: 'disponibles',
      controleurCreating: false,
      editing: false,
      currentControleur: {},
      filterGradeId: null,
      affecterGroupement: {},
      affecterUp: {},
      affecterUtilisateur: {},
      affecterZone: {},
      query: {
        page: 1,
        limit: 100,
        keyword: '',
        grade_id: '',
      },
      dialogCreateControleur: false,
      currentControleurId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('controleur.NomRequired'), trigger: 'blur' }],
        grade_id: [{ required: true, message: this.$t('controleur.GradeControleurRequired'), trigger: 'blur' }],
        prenom: [{ required: true, message: this.$t('controleur.PrenomRequired'), trigger: 'blur' }],
        numero: [{ required: true, message: this.$t('controleur.NumeroRequired'), trigger: 'blur' }],
        adresse: [{ required: true, message: this.$t('controleur.AdresseRequired'), trigger: 'blur' }],
      },
      fileList: [],
      list1: [],
      list2: [],
      select_list: [],
    };
  },
  computed: {

  },
  created() {
    this.resetNewControleur();
    this.getControleursList();
    this.getGradesControleursList();
    this.getGroupementsList();
    this.getUpList();
    this.getZonesList();
    this.getUsersList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    showDetailsPage(id){
      this.$router.push({ path: '/controleur/' + id });
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    async handleAffectUpDialog(controleur){
      // this.affecterUp.user = controleur.nom + ' ' + controleur.prenom;
      // this.affecterUp.controleur_id = controleur.id;
      // this.AffectUpDiolog = true;
      this.affectUniteProductionDialog = true;
      const { data } = await controleurResource.sublist('/' + controleur.id + '/unites_production');
      // this.groupementLoading = false;
      this.up_controleur = data.reverse();
      console.log('LIST ES PRODUCT UP', this.up_controleur);
    },
    async gererGroupementControleur(controleur_id){
      this.controleurGroupementDialog = false;
      this.affectTitile = 'Affecter des Groupements à un Controleur';
      this.affectGroupementControleurDialog = true;
      var outList = [];
      this.groupementsControleur.forEach(groupement => {
        outList.push({ 'id': groupement.id, 'nom': groupement.nom.toUpperCase(), 'description': groupement.description });
      });
      this.query.keyword = '';
      const { data } = await controleurResource.list();
      const list = [];
      this.select_list = [];
      this.list2 = [];
      data.forEach((controleur) => {
        if (controleur.id !== controleur_id){
          list.push({ 'id': controleur.id, 'nom_prenom': controleur.nom + ' ' + controleur.prenom, 'is_active': controleur.is_active });
        }
      });
      this.select_list = list.reverse();
      this.list2 = outList;
    },

    async gererUpControleur(c_controleur){
      await this.handleAffectUpDialog(c_controleur);
      this.affectTitile = 'Affecter Uniter de Production a un Controleur';
      var outList = [];
      this.up_controleur.forEach(up => {
        outList.push({ 'id': up.id, 'nom': up.nom.toUpperCase(), 'description': up.description });
      });
      this.query.keyword = '';
      const { data } = await controleurResource.list();
      const list = [];
      data.forEach((controleur) => {
        if (controleur.id !== c_controleur.id){
          list.push({ 'id': controleur.id, 'nom_prenom': controleur.nom + ' ' + controleur.prenom, 'is_active': controleur.is_active });
        }
      });
      this.select_list = list.reverse();
      this.list2 = outList;
    },
    findGroupement(name){
      const results = [];
      const outList = [];
      this.groupementsControleur.forEach(groupement => {
        outList.push({ 'id': groupement.id, 'nom': groupement.nom.toUpperCase(), 'description': groupement.description });
      });
      this.list2 = outList;
      if (name !== ''){
        this.list2.forEach((groupement) => {
          if (groupement.nom.includes(name.toUpperCase())){
            results.push(groupement);
          }
        });
        this.list2 = results;
      }
    },
    findUp(name){
      const results = [];
      const outList = [];
      this.up_controleur.forEach(up => {
        outList.push({ 'id': up.id, 'nom': up.nom.toUpperCase(), 'description': up.description });
      });
      this.list2 = outList;
      if (name !== ''){
        this.list2.forEach((up) => {
          if (up.nom.includes(name.toUpperCase())){
            results.push(up);
          }
        });
        this.list2 = results;
      }
    },
    searchGroupement(name){
      const results = [];
      this.gererGroupementControleur();
      if (name !== ''){
        this.groupementsControleur.forEach((groupement) => {
          if (groupement.nom.includes(name)){
            results.push(groupement);
          }
        });
        this.groupementsControleur = results;
      }
    },
    envoyerGroupementsControleur(controleur_id){
      const groupement_ids = [];
      this.list1.forEach(groupement => {
        groupement_ids.push(groupement.id);
      });
      const donnee = { 'groupements_id': groupement_ids };
      console.log('le controleur selectioné ', controleur_id, donnee);
      const affectationRessource = new Resource('controleurs/affecter_groupement/' + controleur_id);
      affectationRessource.store(donnee).then(response => {
        this.$message({
          type: 'success',
          message: response.message,
        });
      })
        .catch(error => {
          console.log('error ', error);
        })
        .finally(() => {
          this.affectGroupementControleurDialog = false;
          this.getControleursList();
          this.list1 = [];
        });
    },
    async affecterGroupementControleur(){
      const donnees = {
        'groupements_id': [
          ...this.groupementSelection,
        ],
      };
      const data = await controleurResource.extra('affecter_groupement/' + this.affecterGroupement.producteur_id, donnees);
      if (data.success){
        this.$message({
          type: 'success',
          message: 'Groupement affecté à ' + this.affecterGroupement.user + ' avec succès',
        });
        this.groupementAffectationDiolog = false;
        this.initSearch();
        this.groupementSelection = [];
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
      // this.groupementAffectationDiolog = false;
      this.affecterGroupement = {
        groupement_id: '',
      };
    },
    async affecterUpControleur(controleur_id){
      const upSelection = [];
      this.list1.forEach(up => {
        upSelection.push(up.id);
      });
      console.log('CONTROLEUR CHOISI', controleur_id);
      const donnees = { 'unite_production_ids': upSelection };
      const data = await controleurResource.extra(controleur_id + '/unites_production', donnees);
      if (data.success){
        this.$message({
          type: 'success',
          message: 'Unites de Productions affecté  avec succès',
        });
        this.AffectUpDiolog = false;
        this.initSearch();
        this.upSelection = [];
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
      // this.groupementAffectationDiolog = false;
      this.affecterUp = {
        up_id: '',
      };
    },
    frontFilter(value){
      this.loading = true;
      const result = this.listback.filter((element) => {
        return element.nom.toLowerCase().includes(this.nameSearch.toLowerCase()) || element.prenom.toLowerCase().includes(this.nameSearch.toLowerCase());
      });
      this.list = result;
      this.loading = false;
    },
    getExist(id) {
      // console.log('LES SELECTED : ', this.groupementSelection);
      // console.log('LE CHECK : ', this.groupementSelection.indexOf(id));
      return this.groupementSelection.indexOf(id) !== -1;
    },
    getUpExist(id) {
      return this.upSelection.indexOf(id) !== -1;
    },
    navigateGroupement(id){
      this.$router.push({ path: '/groupement/' + id });
    },
    retirerGroupement(id, controleur_id){
      this.$confirm(this.$t('controleur.retirerGroupementMessage'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(async() => {
        this.groupementLoading = true;
        const data = await retirerResource.get(id);
        if (data.success){
          this.$message({
            type: 'success',
            message: data.message,
          });
          this.initSearch();
          var results = [];
          this.groupementsControleur.forEach(groupement => {
            if (groupement.id !== id) {
              results.push(groupement);
            }
          });
          this.groupementsControleur = results;
          this.groupementLoading = false;
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.statusUpdateError'),
          });
          this.groupementLoading = false;
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Action Annulée !',
        });
      });
    },
    checkGroupementSelectId(id) {
      // console.log('JE SUIS LA', id);
      var tab = this.groupementSelection;
      if (this.getExist(id)) {
        // console.log('existe');
        tab = tab.filter((val) => val !== id);
      } else {
        // console.log('n existe pas');
        tab.push(id);
      }
      this.groupementSelection = tab;
    },
    checkUpSelectId(id) {
      // console.log('JE SUIS LA', id);
      var tab = this.upSelection;
      if (this.getUpExist(id)) {
        // console.log('existe');
        tab = tab.filter((val) => val !== id);
      } else {
        // console.log('n existe pas');
        tab.push(id);
      }
      this.upSelection = tab;
    },
    async handleDisplayGroupementDialog(pass) {
      this.affecterGroupement.user = pass.nom + ' ' + pass.prenom;
      this.affecterGroupement.producteur_id = pass.id;
      this.groupementAffectationDiolog = true;
    },
    async handleDisplayAffectUserDialog(pass) {
      this.affecterUtilisateur.user = pass.nom + ' ' + pass.prenom;
      this.affecterUtilisateur.controleur_id = pass.id;
      this.userAffectationDialog = true;
    },
    async affecterUserControleur(){
      const donnees = {
        controleur_id: this.affecterUtilisateur.controleur_id,
        user_id: this.affecterUtilisateur.user_id,
      };
      const data = await lierResource.store(donnees);
      if (data.success){
        this.$message({
          type: 'success',
          message: 'Le Controleur ' + this.affecterUtilisateur.user + 'peut avec succès avoir accès au système',
        });
        this.initSearch();
        this.userAffectationDialog = false;
        this.affecterUtilisateur = null;
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
        this.userAffectationDialog = false;
        this.affecterUtilisateur = null;
      }
    },
    async affecterZoneControleur(){
      const donnees = {
        'zones_id': [
          this.affecterZone.zone_id,
        ],
      };
      const data = await controleurResource.extra('affecter_zone/' + this.affecterZone.producteur_id, donnees);
      if (data.success){
        this.$message({
          type: 'success',
          message: 'Zone affecté à ' + this.affecterZone.user + ' avec succès',
        });
        this.initSearch();
        this.zoneAffectationDiolog = false;
        this.affecterZone = null;
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
        this.zoneAffectationDiolog = false;
        this.affecterZone = null;
      }
    },
    handleDisplayZoneDialog(pass) {
      this.affecterZone.user = pass.nom + ' ' + pass.prenom;
      this.affecterZone.producteur_id = pass.id;
      this.zoneAffectationDiolog = true;
    },
    async handleDisplayControleurGroupementDialog(pass) {
      // console.log('DONNE PASSES : ', pass);
      console.log('pass controleur_id:', pass);
      this.groupementsControleur = [];
      this.grade_controleur = pass.grade.nom;
      this.controleur_id = pass.id;
      this.groupementLoading = true;
      this.controleurGroupementDialog = true;
      const { data } = await controleurResource.sublist('/' + pass.id + '/groupements');
      this.groupementLoading = false;
      this.groupementsControleur = data;
      console.log('GROUPEMENTS CONTROLEUR', this.groupementsControleur);
    },
    handleDisplayControleurZoneDialog(pass) {
      this.currentZoneData = pass;
      this.controleurZoneDialog = true;
    },
    handleAffecterZoneAControleur(){

    },
    cancelcontroleurForm() {
      this.$refs.CampagneagricoleCreationDrawer.closeDrawer();
    },
    async setControleurStatus(id, type){
      const data = await controleurResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    initSearch(){
      this.handleFilterControleurs();
    },
    async getControleursList() {
      this.loading = true;
      const { data, meta } = await controleurResource.list(this.query);
      this.list = data.reverse();
      this.listback = data.reverse();
      this.total = meta.total;
      console.log('===== EDI');
      console.log(data);
      console.log('===== EVO');
      this.loading = false;
    },
    async getZonesList() {
      const req = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        controleur_id: 'null',
      };
      const { data } = await zoneResource.list(req);
      this.zones = data;
    },
    async getUsersList() {
      const { data } = await userResource.list(this.query);
      this.users = data;
    },
    async getGroupementsList() {
      const req = {
        page: 1,
        limit: 1000,
      };
      const { data } = await groupementResource.list(req);
      const _reg = [];
      data.forEach(groupement => {
        _reg[groupement.id] = groupement.nom;
      });
      this.nomGroupementsDispo = _reg;
      this.groupements = data;
      console.log('LES GROUPEMENTS ', this.groupements);
    },

    async getUpList() {
      const req = {
        page: 1,
        limit: 1000,
      };
      const { data } = await upResource.list(req);
      const _reg = [];
      data.forEach(up => {
        _reg[up.id] = up.nom;
      });
      this.nomUpDispo = _reg;
      this.unite_productions = data;
      console.log('LES ups ', this.unite_productions);
    },
    async getSubControleursList(subUrl) {
      this.loading = true;
      const { data } = await controleurResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterControleurs() {
      this.query.page = 1;
      this.getControleursList();
    },
    filterByGradesControleurs(){
      this.query.grade_id = this.filterGradeId;
      if (this.activeList === 'desactives') {
        this.getSubControleursList('indexdesactive');
      } else if (this.activeList === 'corbeille') {
        this.getSubControleursList('indexinvisible');
      } else {
        this.getControleursList();
      }
    },
    handlecreateControleur() {
      this.editing = false;
      this.resetNewControleur();
      this.dialogCreateControleur = true;
      this.$nextTick(() => {
        this.$refs['controleurForm'].clearValidate();
      });
    },
    handleEditControleur(id){
      this.editing = true;
      this.resetNewControleur();
      this.currentControleur = this.list.find(abonne => abonne.id === id);
      this.dialogCreateControleur = true;
      this.$nextTick(() => {
        this.$refs['controleurForm'].clearValidate();
      });
    },
    handleDeleteControleur(id, visible, libelle) {
      console.log('id : ' + id + 'libelle : ' + libelle);
      this.$confirm(this.$t('controleur.deleteWarn') + ' ' + libelle + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer controleur']))) {
          console.log('result 2 : ');
          this.$swal({
            title: this.$t('general.SureControleur'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              controleurResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('controleur.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setControleurStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createControleur() {
      this.$refs['controleurForm'].validate((valid) => {
        if (valid) {
          this.controleurCreating = true;
          controleurResource
            .store(this.currentControleur)
            .then(async(response) => {
              this.$message({
                message: this.$t('controleur.NewControleur') + ' ' + this.currentControleur.libelle + ' ' + this.$t('controleur.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentControleurId = response.data.id;
              this.resetNewControleur();
              this.dialogCreateControleur = false;
              this.handleFilterControleurs();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.controleurCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    updateControleur() {
      this.$refs['controleurForm'].validate((valid) => {
        if (valid) {
          this.controleurCreating = true;
          controleurResource.update(this.currentControleur.id, this.currentControleur).then(response => {
            this.resetNewControleur();
            this.handleFilterControleurs();
            this.$message({
              message: this.$t('controleur.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateControleur = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.controleurCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewControleur() {
      this.currentControleur = {
        libelle: '',
        grade_id: null,
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('general.code'), this.$t('controleur.libelle')];
        const filterVal = ['id', 'code', 'libelle'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des controleurs SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
    async getGradesControleursList() {
      const { data } = await gradeControleurResource.list(this.query);
      this.gradeControleurs = data;
      const _reg = [];
      data.forEach(gradeControleur => {
        _reg[gradeControleur.id] = gradeControleur.nom;
      });
      this.nomGradeControleur = _reg;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
